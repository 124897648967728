.contact {
  background-color: #f1f1f1;
  padding: 4rem 2rem;
  text-align: center;
}

.contact h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact input,
.contact textarea {
  width: 80%;
  max-width: 500px;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.contact button {
  background-color: #007BFF;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact button:hover {
  background-color: #0056b3;
}

.status-message {
  font-size: 1rem;
  margin-bottom: 1rem;
}
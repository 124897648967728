.home {
  padding-top: 3rem; /* Añadir suficiente padding en la parte superior para el header */
}

.applications {
  background-color: #f9f9f9;
  padding: 4rem 2rem;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.application-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.about-us {
  background-color: #ffffff; /* Color de fondo de la sección */
  padding: 4rem 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.about-us h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.about-us p {
  font-size: 1.25rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 1.5rem; /* Centrar y espaciar el texto */
}

.footer-content {
  background-color: #996565; /* Color de fondo claro para la leyenda */
  padding: 2rem 1rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.6;
}

.footer-content a {
  color: #007BFF;
  text-decoration: none;
}

.footer-content a:hover {
  text-decoration: underline;
}

.terms-of-service {
    padding: 6rem 2rem; /* Espacio adicional para el contenido */
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
    color: #333;
  }
  
  .terms-of-service h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .terms-of-service p,
  .terms-of-service ul {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
  
  .terms-of-service ul li {
    margin-bottom: 0.75rem;
  }
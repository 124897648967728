.autoreply-app {
  font-family: 'Roboto', sans-serif;
  color: #333;
  opacity: 0;
  transition: opacity 1s ease-in;
}

.loaded .autoreply-app {
  opacity: 1;
}

.autoreply-app .hero {
  background-color: #18234c; /* Color de fondo para el Hero */
  padding: 6rem 2rem;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.autoreply-app .hero-logo {
  width: 200px; /* Tamaño ajustado para el logo cuadrado */
  height: auto;
  background-color: #0c133b; /* Color de fondo del logo */
  padding: 1rem;
  border-radius: 10px;
  animation: autoreply-light-up 2s infinite alternate;
}

@keyframes autoreply-light-up {
  0% {
    box-shadow: 0 0 10px #486fbc, 0 0 20px #486fbc, 0 0 30px #486fbc;
  }
  100% {
    box-shadow: 0 0 20px #486fbc, 0 0 40px #486fbc, 0 0 60px #486fbc;
  }
}

.autoreply-app .hero-content h1 {
  font-size: 2.5rem;
  margin-top: 1rem;
  color: #ffffff;
}

.autoreply-app .hero-content p {
  font-size: 1.25rem;
  margin: 1rem 0;
  color: #ffffff;
}

.autoreply-app .hero-buttons {
  margin-top: 1.5rem;
}

.autoreply-app .cta-button {
  background-color: #486fbc;
  color: #fff;
  padding: 0.75rem 2rem;
  border-radius: 0.25rem;
  margin: 0.5rem;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s;
}

.autoreply-app .cta-button:hover {
  background-color: #0c133b;
}

.autoreply-app .details {
  background-color: #ffffff;
  padding: 4rem 2rem;
  text-align: center;
}

.autoreply-app .details h2 {
  font-size: 2rem;
}

.autoreply-app .features-list {
  list-style-type: none;
  padding: 0;
  font-size: 1.25rem;
  margin-top: 1rem;
  text-align: left;
  max-width: 600px;
  margin: 0 auto; /* Centrar la lista */
}

.autoreply-app .features-list li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.autoreply-app .features-list li::before {
  content: '✔'; /* Puedes cambiar esto por cualquier icono que prefieras */
  position: absolute;
  left: 0;
  color: #486fbc; /* Color del icono */
}

.autoreply-app .description {
  background-color: #ffffff;
  padding: 4rem 2rem;
  text-align: center;
}

.autoreply-app .description h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.autoreply-app .highlighted-text {
  background-color: rgba(72, 111, 188, 0.1);
  padding: 1rem;
  border-radius: 0.25rem;
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 3rem;
  max-width: 800px;
  margin: 1rem auto; /* Centrar el texto */
}

.autoreply-app .screenshots {
  background-color: #f9f9f9;
  padding: 4rem 2rem;
  text-align: center;
}

.autoreply-app .screenshots h2 {
  margin-bottom: 2rem;
}

.autoreply-app .screenshot-gallery {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.autoreply-app .screenshot-card {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px; /* Tamaño mayor para las capturas de pantalla */
  padding: 1rem;
  margin: 1rem;
  overflow: hidden;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.autoreply-app .screenshot-card img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 0.25rem;
}

.autoreply-app .screenshot-card p {
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.autoreply-app .screenshot-card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.autoreply-app .screenshot-card.visible {
  opacity: 1;
  transform: translateY(0);
}

.autoreply-app #lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  z-index: 1000; /* Asegura que el lightbox esté por encima de otros elementos */
}

.autoreply-app #lightbox.visible {
  opacity: 1;
  pointer-events: auto;
}

.autoreply-app #lightbox img {
  max-width: 80%;
  max-height: 80%;
  border-radius: 10px;
}

.autoreply-app #lightbox .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  z-index: 1001; /* Asegura que el botón de cierre esté por encima del lightbox */
}
.hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
  transition: opacity 1s ease-in;
}

.hero-video.loaded {
  opacity: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  border-radius: 10px;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-top: 1rem;
}

.hero-content p {
  font-size: 1.25rem;
  margin: 1rem 0;
}

.hero-button {
  background-color: #007BFF;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
}

.hero-button:hover {
  background-color: #0056b3;
}
.fulldockmanager-app {
    font-family: 'Roboto', sans-serif;
    color: #333;
    opacity: 0;
    transition: opacity 1s ease-in;
  }
  
  .loaded .fulldockmanager-app {
    opacity: 1;
  }
  
  .fulldockmanager-app .hero {
    background: linear-gradient(135deg, #6b5153, #61667c, #0d141b, #1e2d48); /* Degradado para el Hero */
    background-size: cover;
    background-position: center;
    padding: 6rem 2rem;
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fulldockmanager-app .hero-logo {
    width: 200px; /* Ajustado para el logo cuadrado */
    height: auto;
    background-color: #6b5153; /* Color de fondo del logo */
    padding: 1rem;
    border-radius: 10px;
    animation: light-up 2s infinite alternate;
  }
  
  @keyframes light-up {
    0% {
      box-shadow: 0 0 10px #cd5c4f, 0 0 20px #cd5c4f, 0 0 30px #cd5c4f;
    }
    100% {
      box-shadow: 0 0 20px #cd5c4f, 0 0 40px #cd5c4f, 0 0 60px #cd5c4f;
    }
  }
  
  .hero-content h1 {
    font-size: 2.5rem;
    margin-top: 1rem;
    color: #ffffff;
  }

  .hero-content p {
    font-size: 1.25rem;
    margin: 1rem 0;
    color: #ffffff;
  }
  
  .fulldockmanager-app .hero-buttons {
    margin-top: 1.5rem;
  }
  
  .cta-button {
    background-color: #cd5c4f;
    color: #fff;
    padding: 0.75rem 2rem;
    border-radius: 0.25rem;
    margin: 0.5rem;
    display: inline-flex;
    align-items: center;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #9b4139;
  }
  
  .details {
    background-color: #ffffff;
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .details h2 {
    font-size: 2rem;
  }
  
  .features-list {
    list-style-type: none;
    padding: 0;
    font-size: 1.25rem;
    margin-top: 1rem;
    text-align: left;
    max-width: 600px;
    margin: 0 auto; /* Centrar la lista */
  }
  
  .features-list li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .features-list li::before {
    content: '✔'; /* Puedes cambiar esto por cualquier icono que prefieras */
    position: absolute;
    left: 0;
    color: #cd5c4f; /* Color del icono */
  }
  
  .description {
    background-color: #ffffff;
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .description h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .highlighted-text {
    background-color: rgba(206, 92, 79, 0.1); /* Fondo semitransparente */
    padding: 1rem;
    border-radius: 0.25rem;
    font-size: 1.1rem;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 3rem;
    max-width: 800px;
    margin: 1rem auto; /* Centrar el texto */
  }
  
  .screenshots {
    background-color: #f9f9f9;
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .screenshots h2 {
    margin-bottom: 2rem;
  }
  
  .screenshot-gallery {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .screenshot-card {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px; /* Tamaño mayor para las capturas de pantalla */
    padding: 1rem;
    margin: 1rem;
    overflow: hidden;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .screenshot-card img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 0.25rem;
  }
  
  .screenshot-card p {
    margin-top: 0.5rem;
    font-size: 0.9rem;
  }
  
  .screenshot-card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
  
  .screenshot-card.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  #lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s;
    z-index: 1000; /* Asegura que el lightbox esté por encima de otros elementos */
  }
  
  #lightbox.visible {
    opacity: 1;
    pointer-events: auto;
  }
  
  #lightbox img {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
  }
  
  #lightbox .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    z-index: 1001; /* Asegura que el botón de cierre esté por encima del lightbox */
  }
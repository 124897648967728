.footer {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    text-align: center;
  }
  
  .footer .social a, .footer .legal a {
    color: #fff;
    margin: 0 1rem;
    transition: color 0.3s;
  }
  
  .footer .social a:hover, .footer .legal a:hover {
    color: #007BFF;
  }
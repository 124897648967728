body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  color: #333333;
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #333;
  padding: 1rem 2rem;
  color: #fff;
}

.content {
  margin-top: 4rem; /* Espacio para el header */
  padding: 2rem;
}

.privacy-policy, .terms-of-service {
  padding: 6rem 2rem; /* Espacio adicional para el contenido */
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  color: #333;
}

.privacy-policy h2, .terms-of-service h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.privacy-policy p, .terms-of-service p,
.privacy-policy ul, .terms-of-service ul {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.privacy-policy ul li, .terms-of-service ul li {
  margin-bottom: 0.75rem;
}

footer {
  background-color: #333;
  color: #fff;
  padding: 2rem;
  text-align: center;
}

footer a {
  color: #007BFF;
}

footer a:hover {
  text-decoration: underline;
}

footer .social, footer .legal {
  margin-bottom: 1rem;
  font-size: 0.875rem;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.header .logo a {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
}

.header .nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header .nav ul li {
  margin: 0 1rem;
  position: relative;
}

.header .nav ul li a {
  color: #ffffff;
  transition: color 0.3s;
  border-bottom: none; /* Quitar el subrayado */
}

.header .nav ul li a:hover {
  color: #007BFF;
}

.header .nav ul li ul.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  visibility: hidden;
  width: max-content; /* Asegura que el ancho sea suficiente para las opciones */
  overflow: visible; /* Asegura que las opciones no sean ocultas */
  min-width: 150px; /* Asegura que haya suficiente espacio incluso para palabras largas */
}

.header .nav ul li:hover ul.submenu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.header .nav ul li ul.submenu li {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  transition: background-color 0.3s ease; /* Añadir transición al fondo */
}

.header .nav ul li ul.submenu li:hover {
  background-color: #007BFF; /* Cambiar color de fondo al pasar el mouse */
}

.header .nav ul li ul.submenu li a {
  color: #ffffff;
}

.header .cta .cta-button {
  background-color: #007BFF;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
}

.header .cta .cta-button:hover {
  background-color: #0056b3;
}
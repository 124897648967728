.application-card {
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 0.5rem;
  transition: transform 0.3s, box-shadow 0.3s; /* Agregar una transición para la sombra */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%; /* Asegurarse de que todas las tarjetas tienen la misma altura */
}

.application-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Añadir una sombra al hacer hover */
}

.application-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 10px; /* Suavizar esquinas */
  object-fit: cover; /* Asegurarse de que las imágenes se ajusten al contenedor */
  width: 150px; /* Establecer un ancho mínimo consistente */
  height: 150px; /* Establecer una altura mínima consistente */
}

.application-card h3 {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  min-height: 3rem; /* Asegurar una altura mínima para los títulos */
}

.application-card p {
  font-size: 1rem;
  line-height: 1.5;
  flex-grow: 1; /* Permitir que la descripción use el espacio disponible */
}

.app-description {
  min-height: 3rem; /* Asegurar un tamaño mínimo consistente en las descripciones */
}

.app-button {
  background-color: #007BFF;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  display: inline-block;
  margin-top: 1rem;
  transition: background-color 0.3s;
  align-self: center; /* Asegurar que el botón esté centrado horizontalmente */
}

.app-button:hover {
  background-color: #0056b3;
}